import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
    Box,
    Typography,
    TextField,
    Button,
    AppBar,
    Toolbar,
    IconButton,
    List,
    ListItem,
    Card,
    CardContent, Container
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LoadingPage from "./LoadingPage";

const CustomerPage = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [customer, setCustomer] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchCustomer = async () => {
            try {
                const response = await axios.get(`/customer/getById/${id}`);
                setCustomer(response.data);
            } catch (error) {
                console.error("Error fetching customer details:", error);
            }
        };
        fetchCustomer();
    }, [id]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCustomer((prevCustomer) => ({
            ...prevCustomer,
            [name]: value,
        }));
    };

    const handleSave = async () => {
        try {
            await axios.put(`/customer/update/${id}`, {
                name: customer.name,
                email: customer.email,
                information: customer.information,
            });
            navigate("/customer");
        } catch (error) {
            setError("Failed to save changes.");
        }
    };

    return (
        <>
            <AppBar position="sticky">
                <Toolbar>
                    <IconButton color="inherit" onClick={() => navigate("/customer")}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h6" sx={{ marginLeft: "10px" }}>
                        {customer?.name}
                    </Typography>
                </Toolbar>
            </AppBar>
            {customer ?
                <Container sx={{py: "2em"}}>
                    <Typography variant="h5" gutterBottom>Edit Customer Information</Typography>
                    <TextField
                        label="Name"
                        name="name"
                        value={customer.name || ""}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Email"
                        name="email"
                        value={customer.email || ""}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Additional Information"
                        name="information"
                        value={customer.information || ""}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                        multiline
                        maxRows={20}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSave}
                        sx={{ marginTop: 2 }}
                    >
                        Save Changes
                    </Button>
                    {error && <Typography color="error" sx={{ marginTop: 2 }}>{error}</Typography>}
                </Container>
                :
                <LoadingPage/>
            }
        </>
    );
};

export default CustomerPage;
