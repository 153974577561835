import React, {useEffect, useRef, useState} from 'react';
import axios from 'axios';
import LoadingPage from "./LoadingPage";
import { Box, Typography, TextField, IconButton, AppBar, Toolbar } from '@mui/material';
import { useNavigate, useParams } from "react-router-dom";
import SendIcon from '@mui/icons-material/Send';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MessageBubble from "../components/MessageBubble";

const ChatPage = ({chats, updateMessages}) => {
    const navigate = useNavigate();
    const { chatId } = useParams();
    const [newMessage, setNewMessage] = useState("");
    const [loadedAllMessages, setLoadedAllMessages] = useState(false);
    const scrollRef = useRef(null);

    function getMyChat(){
        return chats.find(x=>x._id===chatId);
    }

    useEffect(() => {
        const fetchChatDetails = async () => {
            try {
                const response = await axios.get(`/chat/getById/${chatId}`);
                updateMessages(response.data.messages);
                setLoadedAllMessages(true);
            } catch (error) {
                console.error("Error fetching chat details:", error);
            }
        };

        fetchChatDetails();
    }, [chatId]);

    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [getMyChat().messages.length, loadedAllMessages]);

    const sendMessage = async () => {
        if (newMessage !== "") {
            const msg = newMessage;
            setNewMessage("");
            try {
                await axios.post(`/chat/sendAssistantMessage/${chatId}`, {
                    content: msg,
                });

            } catch (error) {
                setNewMessage(msg);
                console.error("Error sending message:", error);
            }
        }
    };

    return (
        <>
            <AppBar position="sticky">
                <Toolbar>
                    <IconButton color="inherit" onClick={() => navigate("/chat")}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h6" sx={{ marginLeft: "10px" }}>
                        {getMyChat().name}
                    </Typography>
                </Toolbar>
            </AppBar>
            {loadedAllMessages ?
                <Box flex={1} overflow="auto" p={2} pb={12} sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                }}>
                    {getMyChat().messages.map((message) => (
                        <MessageBubble key={message._id} message={message} handleSuggestedAnswerClick={(answer) => setNewMessage(answer)}/>
                    ))}
                    <div ref={scrollRef}/>
                </Box>
                :
                <LoadingPage />
            }
            <Box sx={{
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 0,
                p: 2,
                bgcolor: 'background.paper',
                borderTop: '1px solid',
                borderColor: 'divider',
                display: 'flex',
                gap: 1,
                alignItems: 'center',
            }}
            >
                <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Type a message"
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    multiline
                    maxRows={4}
                />
                <IconButton color="primary" onClick={sendMessage}>
                    <SendIcon />
                </IconButton>
            </Box>
        </>
    );
};

export default ChatPage;
