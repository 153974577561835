import React, { useEffect, useState } from "react";
import {AppBar, Toolbar, IconButton, Typography, TextField, Button, Box, Container} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

const LawFieldPage = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");

    // Fetch existing LawField if `id` is provided
    useEffect(() => {
        if (id!=="new") {
            const fetchLawField = async () => {
                try {
                    const response = await axios.get(`/knowledge/lawfield/getById/${id}`);
                    setTitle(response.data.title);
                    setContent(response.data.content);
                } catch (error) {
                    console.error("Error fetching law field:", error);
                }
            };
            fetchLawField();
        }
    }, [id]);

    // Handle form submission to create or update LawField
    const handleSubmit = async () => {
        try {
            if (id!=="new") {
                // Update existing LawField
                await axios.put(`/knowledge/lawfield/update/${id}`, { title, content });
            } else {
                // Create new LawField
                await axios.post("/knowledge/lawfield/create", { title, content });
            }
            navigate("/lawfield");
        } catch (error) {
            console.error("Error saving law field:", error);
        }
    };

    return (
        <>
            <AppBar position="sticky">
                <Toolbar>
                    <IconButton color="inherit" onClick={() => navigate("/lawfield")}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h6" sx={{ marginLeft: "10px" }}>
                        {id ? "Edit Law Field" : "Create Law Field"}
                    </Typography>
                </Toolbar>
            </AppBar>

            <Container sx={{py: "1em"}}>
                <TextField
                    label="Title"
                    variant="outlined"
                    fullWidth
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    margin="normal"
                />
                <TextField
                    label="Content"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={4}
                    value={content}
                    onChange={(e) => setContent(e.target.value)}
                    margin="normal"
                />
                <Button variant="contained" color="primary" onClick={handleSubmit}>
                    Save
                </Button>
            </Container>
        </>
    );
};

export default LawFieldPage;
